var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    section: true,
    'section-image-or-video': true,
    [`section${_vm.orderNo}`]: true
  },style:({
    height: `calc(${_vm.windowHeight}px - ${_vm.headerHeight}px)`
  })},[_c('a',{staticClass:"navigation-target",attrs:{"id":_vm.hash,"href":"javascript:void(0);"}}),_vm._v(" "),(_vm.hasBackgroundOverlay)?_c('div',{staticClass:"gradient",style:({ background: `rgba(0,0,0,${_vm.overlayOpacity})` })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"title-bottom"},[_c('h1',[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),_c('p',{staticClass:"line-text",domProps:{"innerHTML":_vm._s(_vm.$tdb(_vm.welcome))}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"scroll-element-desktop",on:{"click":_vm.goToNextSection}},[_c('span',{staticClass:"icon-scroll-down"},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"angle-down","size":17,"color":"#fff"}})],1),_vm._v("\n      Scroll down for more information\n    ")])]),_vm._v(" "),_c('span',{staticClass:"scroll-element-mobile",on:{"click":_vm.goToNextSection}},[_c('span',{staticClass:"icon-scroll-down"},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"angle-down","size":17,"color":"#fff"}})],1)]),_vm._v(" "),_c('OrganismsPortfolioOrganismProjectSlider',{attrs:{"slider-data":_vm.sliderData},on:{"thumb-change":_vm.handleThumbChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }